import { template as template_8b53ebd075d24c87bfa0b4cb960b6c0b } from "@ember/template-compiler";
const WelcomeHeader = template_8b53ebd075d24c87bfa0b4cb960b6c0b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
