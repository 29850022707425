import { template as template_26de223af4f04503a212817c76bcdaaf } from "@ember/template-compiler";
const FKText = template_26de223af4f04503a212817c76bcdaaf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
