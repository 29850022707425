import { template as template_ad059816d2e2494f83c6d57cecea747d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ad059816d2e2494f83c6d57cecea747d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
